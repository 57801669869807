import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {FrontContextProvider} from './providers/frontContext';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client";

import 'bootstrap/dist/css/bootstrap.min.css';

console.log(process.env);

const client = new ApolloClient({
  uri:  process.env.REACT_APP_FRONT_INTEGRATION_API_URL,
  cache: new InMemoryCache()
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <FrontContextProvider>
        <App />
      </FrontContextProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);