import OrganizationsDatabasesInfo from "./OrganizationsDatabasesInfo";
import recombeeLogo from "../images/recombee_logo.png"
import {useFrontContext} from "../providers/frontContext";
import React, {useState} from "react";
import GoogleSignIn from "./GoogleSignIn";
import {isRecombeeEmail} from "../util";
import Assistant from "./Assistant";


function FrontSideBar() {
    const context = useFrontContext();

    const [pluginUserCredential, setPluginUserCredential] = useState();

    const [recipient, setRecipient] = useState();
    const [emailAddress, setEmailAddress] = useState();

    if (context.conversation.recipient.handle !== recipient)
    {
        let handle = context.conversation.recipient.handle;
        setRecipient(handle);
        if (isRecombeeEmail(handle)) {
            context.listRecipients().then(otherRecipients => {
                otherRecipients = otherRecipients.results.filter((r) => !isRecombeeEmail(r.handle));
                if(otherRecipients.length > 0) {
                    setEmailAddress(otherRecipients[0].handle);
                }
                else {
                    setEmailAddress(handle);
                }
            })
        }
        else {
            setEmailAddress(handle)
        }
    }

    let content = <GoogleSignIn setPluginUserCredential={setPluginUserCredential}/>;
    if (pluginUserCredential) {
        content = <OrganizationsDatabasesInfo recipient={emailAddress} pluginUserCredential={pluginUserCredential}/>;
    }

    return  (
    <div className="App">
      <img src={recombeeLogo}  alt="Recombee logo"/>
      <div className="px-2">
          {content}
          <Assistant pluginUserCredential={pluginUserCredential}/>
      </div>
    </div>
    );
}

export default FrontSideBar;